import Vue from 'vue';
import {get, post, put} from '@/services/api';
import {
  companyIndex,
  userCreateCompany,
  creditOrderView,
  InvoiceInformationIndex,
  InvoiceConfigurationSave
} from '../../../services/endpoint.json';
import {VueTelInput} from 'vue-tel-input';
import {decrypt} from "@/helpers/encrypt";
import show_alert_mixin from "@/utils/show_alert_mixin";

const fieldCompany = {
  userId: '',
  emailCompany: '',
  nameCompany: '',
  numberCompany: '',
  legalRepresentative: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  continent: '',
  phone: '',
  website: '',
  socialMedia: {
    facebook: '',
    instagram: '',
    twitter: '',
  },
  typeCompany: '',
  zipCode: '',
  otherDsg: '',
  brandOtherDsg: '',
  distributorVag: '',
  elevator: '',
  dinanometer: 0,
  repairDsg: 0,
  sellingHardwareDsg: 0,
  aboutTvs: '',
  promotionalActivities: '',
  futureTvs: '',
  registerSubdealer: '',
};

export default Vue.extend({
  name: 'Profile',
  mixins: [show_alert_mixin],
  components: {
    VueTelInput,
  },
  data: () => ({
    company: fieldCompany,
    invoiceConfiguration: {
      accountHolder: null,
      account: null,
      swiftCode: null,
      address: null
    },
    userId: null,
    showButtonUpdateCompany: true,
    showButtonUpdateInvoice: true
  }),
  computed: {},
  mounted() {
    const storage = localStorage.getItem("admin-id")
    const temp = decrypt(storage)
    this.userId = temp
    this.getData().then()
  },
  methods: {
    async getData() {
      const {data} = await get(`${companyIndex}/${this.userId}`, null, true).then()
      const tempInvoice = await post(InvoiceInformationIndex, {id: 3}, true)
      if (data.message.company !== null) {
        this.company = data.message.company
        this.showButtonUpdateCompany = false
      }
      if (tempInvoice.data.message.configuration !== null && tempInvoice.data.message.configuration !== undefined) {
        const invoice = tempInvoice.data.message.configuration.extraData
        this.showButtonUpdateInvoice = false
        this.invoiceConfiguration = invoice
      }
    },
    async updateCompany() {
      this.company.userId = this.userId
      this.company.id = this.userId
      this.company.other = true
      await post(userCreateCompany, this.company, true).then()
      this.getData().then()
      this.showAlertNotification("Information updated")
    },
    updateInvoiceConfiguration() {
      if (this.invoiceConfiguration.address !== null && this.invoiceConfiguration.swiftCode !== null
        && this.invoiceConfiguration.accountHolder !== null && this.invoiceConfiguration.account !== null) {
        post(InvoiceConfigurationSave, this.invoiceConfiguration, true).then()
        this.getData().then()
        this.showAlertNotification("Information updated")
      } else {
        this.showAlertNotification("All fields are required", 'error')
      }
    },
  }
});
